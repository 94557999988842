import React from "react";
import { Square } from "./Square";

function App() {
  return (
    // <Routes>
    //   <Route path="/" element={<Layout />}>
    //     <Route index element={<Square />} />
    //     <Route path="/square" element={<Square />} />
    //     {/* <Route path="dashboard" element={<Dashboard />} /> */}

    //     {/* Using path="*"" means "match anything", so this route
    //         acts like a catch-all for URLs that we don't have explicit
    //         routes for.
    //     <Route path="*" element={<NoMatch />} /> */}
    //   </Route>
    // </Routes>
    <Square />
  );
}

export default App;

function Layout() {
  return <div>Payments</div>;
}
