import { useEffect, useState } from "react";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import axios from "axios";
import { useLocation } from "react-router-dom";

export const format = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
});
export const Square = () => {
  const [isReady, setIsReady] = useState(false);
  const [appId, setAppId] = useState("");
  const [locationId, setLocationId] = useState("");
  const [errorText, setErrorText] = useState("");
  const location = useLocation();
  console.log("location", location);
  useEffect(() => {
    setErrorText("");
    if (location.search.includes("?txId=")) {
      const splitted = location.search.split("&");
      const txId = splitted?.[0]?.split("=")?.[1];
      const userToken = splitted?.[1]?.split("=")?.[1];
      const companyId = splitted?.[2]?.split("=")?.[1];
      ///const txIdIsnum = /^\d+$/.test(txId);

      console.log("txId", txId);
      console.log("userToken", userToken);
      console.log("companyId", companyId);

      if (txId && userToken && companyId) {
        try {
          axios
            .get(`https://jsonplaceholder.typicode.com/users`)
            .then((res) => {
              const persons = res.data;
              axios
                .get(
                  `https://spark.localigniter.com/api/v1/companies/${companyId}`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: userToken,
                    },
                  }
                )
                .then((companyRes) => {
                  console.log("companyRes", companyRes);
                  if (companyRes?.data?.payment_methods?.square?.enabled) {
                    setAppId(companyRes?.data?.payment_methods?.square?.app_id);
                    setLocationId(
                      companyRes?.data?.payment_methods?.square?.location_id
                    );
                    setIsReady(true);
                  }
                });
            });
        } catch (e) {
          setErrorText("Error - Transaction not found");
        }
      } else {
        if (!txId) {
          setErrorText("Error - No transaction ID");
        } else if (!userToken) {
          setErrorText("Error - No user token");
        } else {
          setErrorText("Unknown error occured");
        }
      }
    } else {
      setErrorText("Unknown error occured");
    }
  }, [location.search]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <div style={{ width: "100%", maxWidth: 1000, padding: 20 }}>
        {isReady && locationId && appId ? (
          <>
            <h2>{format.format(189)} will be charged</h2>
            <PaymentForm
              /**
               * Identifies the calling form with a verified application ID generated from
               * the Square Application Dashboard.
               */
              //applicationId="sandbox-sq0idb-ctGDjYtw2R-O6QbZtpm9lA"
              applicationId={appId}
              /**
               * Invoked when payment form receives the result of a tokenize generation
               * request. The result will be a valid credit card or wallet token, or an error.
               */
              cardTokenizeResponseReceived={(token, buyer) => {
                console.log("token", token);

                //console.info({ token, buyer });
              }}
              /**
               * This function enable the Strong Customer Authentication (SCA) flow
               *
               * We strongly recommend use this function to verify the buyer and reduce
               * the chance of fraudulent transactions.
               */
              createVerificationDetails={() => ({
                amount: "1.00",
                /* collected from the buyer */
                billingContact: {
                  addressLines: ["123 Main Street", "Apartment 1"],
                  familyName: "Doe",
                  givenName: "John",
                  countryCode: "US",
                  city: "London",
                },
                currencyCode: "USD",
                intent: "CHARGE",
              })}
              /**
               * Identifies the location of the merchant that is taking the payment.
               * Obtained from the Square Application Dashboard - Locations tab.
               */
              //locationId="LQMCEZ72RBETM"
              locationId={locationId}
            >
              <CreditCard />
            </PaymentForm>
          </>
        ) : (
          <h2>{errorText || "Loading..."}</h2>
        )}
      </div>
    </div>
  );
};
